<template>
  <div class="college_Th">
    <div class="container">
      <div class="Main">
        <!-- <div class="mb">
          <span>创建企业学堂</span>
        </div>
        <el-steps :active="active" align-center finish-status="success" class="step">
          <el-step title="学堂基本信息"></el-step>
          <el-step title="主体信息"></el-step>
          <el-step title="完成"></el-step>
        </el-steps> -->
        <div class="suc">
          <img src="@/assets/login/success.png" />
          <p>恭喜你，创建成功！</p>
        </div>
        <div class="tup">
          <div>
            <img src="@/assets/college/defaultLogo.png" />
          </div>
        </div>
        <p class="name">{{ name }}</p>
        <div class="sb">
          <span class="btn" @click="goindex()">进入学堂</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCookie } from '@/utils/cookies'
export default {
  data() {
    return {
      active: 3,
      name: this.$route.query.name,
      logo: this.$route.query.logo,
    }
  },
  methods: {
    goindex() {
      let realAuth = JSON.parse(getCookie('SupplyrealAuth'))
      setTimeout(() => {
        let path
        if (realAuth == true) {
          path = '/dashbord'
        } else {
          path = '/userauth'
        }
        this.$router.push({
          path: path,
        })
      }, 1000)
    },
  },
}
</script>
<style scoped>
.college_Th {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}
.container {
  display: flex;
  justify-content: center;
}
.Main {
  width: 1200px;
  height: auto;
  height: 800px;
  margin-top: 80px;
  background: #ffffff;
}
.mb {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.mb span {
  display: inline-block;
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #4d4e5d;
  margin: 20px 27px;
}
.step {
  margin-top: 55px;
}
.Main >>> .el-step__head.is-success {
  color: #ff7b33;
  border-color: #ff7b33;
}
.Main >>> .el-step__title.is-success {
  color: #ff7b33;
}
.Main >>> .el-step__head.is-process {
  color: #a1a3a8;
  border-color: #a1a3a8;
}
.Main >>> .el-step__title.is-process {
  font-weight: 700;
  color: #a1a3a8;
}
.suc {
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.suc img {
  width: 36px;
  height: 36px;
}
.suc p {
  font-size: 24px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  margin-left: 13px;
}
.tup {
  display: flex;
  margin-top: 66px;
  justify-content: center;
}
.tup div {
  width: 123px;
  height: 123px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
}
.tup div img {
  width: 91px;
  height: 93px;
  margin-top: 15px;
}
.name {
  font-size: 24px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: center;
  color: #333333;
  margin: 17px 0 33px 0;
}
.sb {
  text-align: center;
}
.btn {
  display: inline-block;
  text-align: center;
  width: 218px;
  height: 40px;
  background: #ff7b33;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  line-height: 40px;
  cursor: pointer;
}
</style>
